export enum Language {
  POLISH = "polish",
  UKRAINIAN = "ukrainian",
  ENGLISH = "english",
}

export enum LanguageShort {
  PL = "pl",
  UK = "uk",
  EN = "en",
}

export type LanguageName = { [key in LanguageShort]: string };
